<template>
  <div class="bg-grey-90 p-10 <md:px-4">
    <p class="mb-6 text-md fw-bold uppercase md:text-center">
      {{ $t.loyaltyBenefitsTitle }}
    </p>
    <div class="mb-6 flex">
      <vf-icon name="gift" size="lg" />
      <p class="ml-6">
        {{ $t.loyaltyBenefitsDiscount }}
        <vf-link target="_blank" to="/loyalty-terms">
          {{ $t.seeTerms }}
        </vf-link>
        {{ $t.loyaltyBenefitsPromoCodeViaEmail }}
      </p>
    </div>
    <div class="mb-6 flex items-center">
      <vf-icon name="earn" size="lg" />
      <p class="ml-6">
        {{ $t.loyaltyBenefitsEarnPoints }}
      </p>
    </div>
    <!-- GLOBAL15-91445 Remove Free Shipping sentence on community login page for the TBL EU sites FR IT DE GB -->
    <div v-if="!['FR', 'IT', 'DE', 'GB'].includes(currentCountryCode)" class="mb-6 flex items-center">
      <vf-icon name="shipping" size="lg" />
      <p class="ml-6">
        {{ $t.loyaltyBenefitsFreeShipping }}
      </p>
    </div>
    <vf-link style="margin-left: 3.5rem" to="/loyalty">
      {{ $t.learnMore }}
    </vf-link>
  </div>
</template>

<script lang="ts" setup>
const currentCountryCode = useCountryCode()
</script>
